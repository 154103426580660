<template>
  <div class="root">
    <div class="root__item">
      <div class="root__item__main">
        <div>
          <span class="root__item__title">E-mail</span>
          <span class="root__item__value">{{ user.email }}</span>
        </div>
        <AstroButton
          class="root__button-edit"
          :title="titleChange(this.changeEmailActive)"
          @click="changeEmailActive = !changeEmailActive"
        />
      </div>
      <div v-if="changeEmailActive" class="root__item__info">
        <v-form ref="form" class="root__form" v-model="isFormValid" lazy-validation @submit="onSubmitEmail">
          <TextField class="root__item__email" :label="$t('login.newEmail')" type="email" v-model="newEmail" />
          <div class="root__item__info__submit">
            <PasswordField
              class="root__form__password"
              :label="$t('login.currentPassword')"
              v-model="confirmPassword"
              :rules="[rules.required]"
              :disabled="isLoading"
            />
            <AstroButton
              class="root__form__submit"
              type="submit"
              :title="$t('change')"
              :disabled="!isFormValid"
              :loading="isLoading"
            />
          </div>
        </v-form>
      </div>
    </div>
    <div class="root__item">
      <div class="root__item__main">
        <div>
          <span class="root__item__title">{{ $t('login.password') }}</span>
          <span class="root__item__value">{{ $t('updated') }} {{ formatedDate }}</span>
        </div>
        <AstroButton
          class="root__button-edit"
          :title="titleChange(this.changePasswordActive)"
          @click="changePasswordActive = !changePasswordActive"
        />
      </div>
      <div v-if="changePasswordActive" class="root__item__info">
        <v-form ref="form" class="root__form" v-model="isFormValid" lazy-validation @submit="onSubmitPassword">
          <PasswordField
            class="root__form__password"
            :label="$t('login.currentPassword')"
            v-model="currentPassword"
            :rules="[rules.required]"
            :disabled="isLoading"
          />
          <PasswordField
            class="root__form__password"
            :label="$t('login.newPassword')"
            v-model="newPassword"
            :rules="[rules.required]"
            :disabled="isLoading"
          />
          <div class="root__form__last">
            <PasswordField
              class="root__form__password"
              :label="$t('login.repeatPassword')"
              v-model="repeatedPassword"
              :rules="[rules.required, rules.isEqual]"
              :disabled="isLoading"
            />
            <span class="root__form__error">
              {{ error }}
            </span>
            <AstroButton
              class="root__form__submit"
              type="submit"
              :title="$t('change')"
              :disabled="!isFormValid"
              :loading="isLoading"
            />
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AstroButton from '@/elements/components/AstroButton';
import TextField from '@/elements/components/TextField';
import PasswordField from '@/elements/components/PasswordField';
import { UNDEFINED_ERROR_MESSAGE } from '@/constants';

export default {
  name: 'PersonalInfo',
  components: {
    PasswordField,
    TextField,
    AstroButton,
  },
  data() {
    return {
      currentPassword: '',
      passUpdateTime: '',
      newPassword: '',
      repeatedPassword: '',
      confirmPassword: '',
      error: '',
      newEmail: '',
      isFormValid: true,
      isLoading: false,
      changePasswordActive: false,
      changeEmailActive: false,
    };
  },
  computed: {
    ...mapState('UserModule', ['user']),
    ...mapState('UserModule', ['lastPassUpdate']),
    formatedDate() {
      if (this.$i18n.locale === 'en') {
        return new Date(this.lastPassUpdate).toLocaleString('en-US', {
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        });
      } else {
        return new Date(this.lastPassUpdate).toLocaleString('de-DE', {
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        });
      }
    },
    rules() {
      return {
        required: (value) =>
          !!value ||
          (this.$i18n.locale === 'en' ? 'This field should be filled in' : 'Das Feld muss ausgefüllt werden'),
        isEqual: (value) => value === this.newPassword || this.$i18n.t('rules.different'),
      };
    },
  },
  methods: {
    ...mapActions('UserModule', ['addNotification']),
    ...mapActions('UserModule', ['changePassword']),
    ...mapActions('UserModule', ['changeEmail']),
    ...mapActions('UserModule', ['updatePasswordMsg']),
    titleChange(param) {
      if (param) {
        return this.$i18n.t('login.cancel');
      } else {
        return this.$i18n.t('change');
      }
    },
    onSuccessPassword() {
      this.changePasswordActive = false;
      if (this.$i18n.locale === 'ru') {
        this.addNotification({ text: 'Ваш пароль успешно изменён' });
      } else if (this.$i18n.locale === 'de') {
        this.addNotification({ text: 'Ваш пароль успешно изменён' });
      }
    },
    onSuccessEmail() {
      this.changeEmailActive = false;
      this.addNotification({ text: 'Ваш e-mail успешно изменён' });
    },
    async onSubmitPassword(event) {
      event.preventDefault();
      event.stopPropagation();
      this.error = '';

      await this.$refs.form.validate();
      if (!this.isFormValid) {
        return;
      }

      try {
        this.isLoading = true;
        await this.changePassword({
          oldPassword: this.currentPassword,
          newPassword: this.newPassword,
        });
        await this.updatePasswordMsg();
        this.onSuccessPassword();
      } catch (error) {
        const errorMessage = error && error.data.message;

        if (errorMessage === 'Old password does not match') {
          this.error = 'Неверный старый пароль';
        } else {
          this.error = errorMessage || UNDEFINED_ERROR_MESSAGE;
        }
      } finally {
        this.isLoading = false;
      }
    },
    async onSubmitEmail(event) {
      event.preventDefault();
      event.stopPropagation();
      this.error = '';

      await this.$refs.form.validate();
      if (!this.isFormValid) {
        return;
      }

      try {
        this.isLoading = true;
        await this.changeEmail({
          newEmail: this.newEmail,
          password: this.confirmPassword,
        });
        this.onSuccessEmail();
      } catch (error) {
        const errorMessage = error && error.data.message;

        if (errorMessage === 'Old password does not match') {
          this.error = 'Неверный старый пароль';
        } else {
          this.error = errorMessage || UNDEFINED_ERROR_MESSAGE;
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    try {
      await this.updatePasswordMsg();
      // eslint-disable-next-line no-empty
    } catch {}
  },
};
</script>

<style scoped lang="sass">
.root
  display: flex
  flex-direction: column
  width: 100%

  &__item
    display: flex
    padding-bottom: base-unit(20)
    padding-top: base-unit(20)
    border-bottom: 1px solid grey-color('10')
    font-weight: 400
    font-size: base-unit(18)
    flex-direction: column
    width: 100%

    +extra-small-devices
      display: block

    &:last-child
      margin-bottom: 0

    &__title
      display: inline-block
      width: 10%
      text-align: left
      color: grey-color('400')

    &__value
      color: grey-color('500')

    &__info
      margin-top: base-unit(20)
      margin-left: base-unit(77)
      display: flex
      flex-direction: column
      +extra-small-devices
        margin-left: 0

      &__submit
        display: flex
        align-items: center

      button
        margin-left: base-unit(10)

    &__email
      max-width: base-unit(280)
      display: block

    &__main
      width: 100%
      display: flex
      justify-content: space-between
      div
        width: 100%

  &__form

    &__error
      display: block
      font-size: base-unit(14)
      color: red-color('primary')

    &__password
      display: block
      width: base-unit(280)

    &__last
      display: flex
      flex-wrap: wrap
      align-items: center

  &__button-edit
    font-weight: 400
    height: auto
    width: auto
    color: blue-color('primary')
    background: transparent
    border: none
    &:hover
      color: blue-color('primary')
      background: transparent
</style>
