<template>
  <page-template :title="$t('profile')" class="profile-page">
    <personal-info />
  </page-template>
</template>

<script>
import { PageTemplate } from '@/elements/components/page';
import PersonalInfo from '@/elements/pages/profile/PersonalInfo';

export default {
  name: 'ProfilePage',
  components: {
    PersonalInfo,
    PageTemplate,
  },
};
</script>

<style scoped lang="sass">
.profile-page
  flex-direction: column
</style>
